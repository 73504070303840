@tailwind base;
@tailwind components;
@tailwind utilities;


.scrollable::-webkit-scrollbar {
    width: 0.1em;
  }  
  .scrollable::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
.cursor-gradient {
    position: fixed;
    pointer-events: none;
    transform-origin: bottom right;
    height: 100%;
    width: 100%;
    z-index: -1;
    
  }
  @keyframes wiggle {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(15deg);
    }
    50% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-15deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  
  .wiggle {
    animation: wiggle 0.5s ease-in-out 4;
  }
  .animate-emoji {
    transition: transform 0.2s ease-in-out;
  }

@layer utilities{
.homebar-icon {
  @apply duration-300 mt-2 flex items-center;
}

.project-buttons{
  @apply  transition duration-500 group-hover:-translate-x-1 m-1 p-1 rounded-full font-semibold text-slate-400  border-teal-700 border-2 bg-opacity-5 hover:bg-teal-600 hover:text-slate-800 hover:border-slate-700 ;
}

}